<template>
  <div class="SubscriptionTable mt-5">
    <!--TITULO DE LA TABLA-->
    
    <div class="TableTitle d-flex justify-space-between align-center">
      <!--TITULO-->
      SLU Users
      <div class="finishButtonCont mt-4 d-flex justify-end align-center">
        <v-btn
          rounded
          outlined
          large
          class="finishBtn"
          @click="goToCreate"
        >
          Create User
        </v-btn>
      </div>

      <div class="TableControlsCont ShowFlexOnMovil justify-space-between align-center">
        <div @click="moveLeftTable()" class="ControlBtn d-flex justify-center align-center">
          <v-icon>mdi-chevron-left</v-icon>
        </div>

        <div @click="moveRightTable()" class="ControlBtn d-flex justify-center align-center">
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </div>
    </div>
    
    <div class="TableContent">
      <!--CABEZA DE LA TABLA-->
      <div class="TableHeadContent mt-5 pl-12 pr-12 d-flex justify-start align-start">
        <!--NOMBRES DE LAS COLUMNAS-->
        <div class="TableHeadName d-flex justify-start align-center">
          ID
        </div>
        <div class="TableHeadName d-flex justify-start align-center">
          Name
        </div>
        <div class="TableHeadName d-flex justify-start align-center">
          Last Name
        </div>
        <div class="TableHeadName d-flex justify-start align-center">
          Email
        </div>
        <div class="TableHeadName d-flex justify-start align-center">
          Country
        </div>
        <div class="TableHeadName d-flex justify-start align-center">
          Company
        </div>
        <div class="TableHeadName d-flex justify-start align-center">
          Created 
        </div>
      </div>

      <!--CUERPO DE LA TABLA-->
      <div class="TableBodyContent pl-12 pr-12 mt-4">
        <!--
			AQUI EMPIEZA EL CICLO A ITERAR 
			PARA MOSTRAR LAS FILAS DE LA TABLA
		-->
        <div v-for="(item, i) in list" :key="i" class="TableBodyContSn d-flex justify-start align-start">
          <!--INFORMACIÓN DE LA TABLA-->
          <div class="TableBodyTextLink d-flex justify-start align-center" @click="setSubscriptionInfo(item)">
            {{ item.id != undefined ? item.id : 'N/D' }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            {{ item.name != undefined ? item.name : 'N/D' }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            {{ item.lastName != undefined ? item.lastName : 'N/D' }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            {{ item.email != undefined ? item.email : 'N/D' }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            {{ item.country != undefined ? item.country : 'N/D' }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            {{ item.company != undefined ? item.company : 'N/D' }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            {{  formatDateMoment(item.createdAt) }}
          </div>
          <div class="TableBodyText d-flex justify-start align-center">
            <v-btn 
              @click="deleteUser(item)"
              icon
              >
              <v-icon>
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Utils from '../../lib/utils';


export default {
  name: 'UsersTable',
  data() {
    return {
      formatDateMoment: Utils.formatDateMoment,
      pagination: {
        limit: 10,
        offset: 0,
      },
      columsArray: [
        {
          refNumber: 'Ref number',
          country: 'Country',
          typeOfRisk: 'Type of Risk ',
          Broker: 'Broker',
          inceptionDate: 'Inception date',
          expiringDate: 'Expiring date',
          status: 'status',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      list: (state) => state.auth.list,
    }),
  },
  methods: {
    ...mapActions(['getListUsers', 'deleteUser']),
    moveRightTable() {
      this.sideScroll('right', 25, 100, 10);
    },
    moveLeftTable() {
      this.sideScroll('left', 25, 100, 10);
    },
    goToCreate() {
      this.$router.push({ name: 'create-users' })
    },
    sideScroll(direction, speed, distance, step) {
      var container = document.querySelector('.TableContent');
      var scrollAmount = 0;
      var slideTimer = setInterval(function() {
        if (direction == 'left') {
          container.scrollLeft -= step;
        } else {
          container.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
    setSubscriptionInfo(item) {
      console.log('Carga de item', item);
      this.loadSubscription(item);
      this.$router.push(`/subscription/${item.id}/submission`);
    },
  },
  beforeMount() {
    this.$emit("startLoading");

    this.getListUsers().then(
      () => {
        this.$emit("finishLoading", "loading");
      }
    );
  }
};
</script>

<style lang="less">
@WidthColTable: 12.5%; //ANCHO DE LAS COLUMNAS
@BorderTable: solid 1px black; //BORDES DE LAS TABLAS
//CONTENEDOR GENERAL DE LA TABLA
.SubscriptionTable {
  width: 100%;
  height: auto;

  //TITULO DE LA TABLA
  .TableTitle {
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 1.5rem;
  }

  //CONTENEDOR D ELA TABLA
  .TableContent {
    width: 100%;
    height: auto;
    overflow: auto;
    //CABEZA DE LA TABLA
    .TableHeadContent {
      width: 100%;
      height: 60px;
      border-radius: 100px;
      border: @BorderTable;

      //NOMBRES DE LAS TABLAS
      .TableHeadName {
        width: @WidthColTable;
        height: 100%;
        font-weight: bold;
      }
    }

    //CUERPO DE LA TABLA
    .TableBodyContent {
      width: 100%;
      height: 370px;
      border-radius: 40px;
      border: @BorderTable;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      overflow-y: auto;

      //CONTENEDOR DE LAS FILAS DE LA TABLA
      .TableBodyContSn {
        width: 100%;
        height: 50px;

        //INFORMACION DE LA TABLA
        .TableBodyText {
          width: @WidthColTable;
          height: 100%;
        }
        .TableBodyTextLink {
          width: @WidthColTable;
          height: 100%;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .SubscriptionTable {
    overflow-y: auto;

    //TITULO DE LA TABLA
    .TableTitle {
      height: 30px;
      font-size: 17px;
    }

    //CONTROLES DE LA TABLA EN MOVILES
    .TableControlsCont {
      width: 65px;
      height: 30px;
      border: solid 1px black;
      border-radius: 15px;

      .ControlBtn {
        width: 45%;
        height: 100%;
      }
    }

    .TableContent {
      //CABEZA DE LA TABLE
      .TableHeadContent {
        width: 750px;
      }
      //CUERPO DE LA TABLA
      .TableBodyContent {
        width: 750px;
      }
    }
    //CARGAR MAS
    .LoadMoreContent {
      .loadMoreButton {
        width: 100%;
      }
    }
  }
}
</style>
