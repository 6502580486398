moment.locale('es');
import moment from 'moment';

function formatDateMoment(dateToFormat) {
  if (dateToFormat) {
    const date = moment(dateToFormat);
    const day = date.format('DD');
    const month = date.format('MMMM').toUpperCase();
    const year = date.format('YYYY').toUpperCase();
    const hour = date.format('HH:mm').toUpperCase();
    return `${day} de ${month} del ${year}`;
  } else {
    return dateToFormat;
  }
}

export default {
  formatDateMoment
}